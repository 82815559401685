<template>
  <div class="user">
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title" v-if="openState">
          同意退款:{{ userdelinfo.number }}
        </div>
        <div class="title" v-if="!openState">
          拒绝退款:{{ userdelinfo.number }}
        </div>
      </div>
      <!-- <div>{{ userdelinfo.number }}</div> -->
      <div>
        <el-input type="textarea"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="catalogue"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="getInfo.status"
            @change="changeState"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <div class="border">
            <div
              @click="changeState(item)"
              v-for="item in list"
              :key="item.name"
              class="border-item"
              :class="[item.state == state ? 'active' : '']"
            >
              {{ item.name }}
            </div>
          </div> -->
        </el-col>
        <el-col :span="5">
          <el-tooltip
            v-if="orderFalag"
            class="item"
            effect="dark"
            content="打开订单提示音"
            placement="top-start"
          >
            <el-button type="primary" @click="openOrder"
              >打开订单提示音</el-button
            >
          </el-tooltip>
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            content="关闭订单提示音"
            placement="top-start"
          >
            <el-button type="danger" @click="closeOrder"
              >关闭订单提示音</el-button
            >
          </el-tooltip>
          <!-- <div>
            <i class="el-icon-microphone" @click="play()" ></i>
            <i class="el-icon-video-pause" @click="paused()"></i> 
            <i class="el-icon-video-play" @click="goahead()" ></i>
          </div> -->
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions :column="3" border>
              <el-descriptions-item label="订单类型">{{
                props.row.order_type
              }}</el-descriptions-item>
              <el-descriptions-item label="商品名称">{{
                props.row.goods_name
              }}</el-descriptions-item>
              <el-descriptions-item label="订单总价">{{
                props.row.total_amount
              }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="商品图片">
          <template slot-scope="scope">
            <div class="image">
              <img
                :src="scope.row.thumb"
                alt=""
                width="100"
                v-if="scope.row.thumb"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="order_sn" label="订单编号"> </el-table-column>
        <el-table-column prop="create_time" label="下单时间"></el-table-column>
        <!-- <el-table-column prop="total_amount" label="订单总价"></el-table-column> -->
        <el-table-column
          prop="pay_amount"
          label="支付金额"
          width="100px"
        ></el-table-column>
        <el-table-column prop="payment_time" label="支付时间"></el-table-column>
        <el-table-column prop="status" label="订单状态" width="100px">
          <template slot-scope="scope">
            <!-- 0待付款；1待发货；2已发货；3已完成；4已关闭；5无效订单 -->
            <el-tag v-show="scope.row.status == 0">待付款</el-tag>
            <el-tag v-show="scope.row.status == 1">待发货</el-tag>
            <el-tag v-show="scope.row.status == 2">已发货</el-tag>
            <el-tag type="success" v-show="scope.row.status == 3"
              >已完成</el-tag
            >
            <el-tag v-show="scope.row.status == 4">已关闭</el-tag>
            <el-tag v-show="scope.row.status == 5">无效订单</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-check"
              @click="conOpen(scope.row)"
            ></el-button>
            <el-button
              type="danger"

              icon="el-icon-close"
              @click="refOpen(scope.row)"
            ></el-button>
            <el-button
       
              icon="el-icon-search"
              @click="searchOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.list_num"
        background
        :total="exp.total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList, getInfo } from "@/api/order.js";
import Speech from "speak-tts";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      optionsStatus: [
        {
          value: "0",
          label: "待付款",
        },
        {
          value: "1",
          label: "待发货",
        },
        {
          value: "2",
          label: "已发货",
        },
        {
          value: "3",
          label: "已完成",
        },
        {
          value: "4",
          label: "已关闭",
        },
        {
          value: "5",
          label: "售后订单",
        },
      ],
      speech: null,
      state: 0,
      openState: false,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //总页数
      exp: {
        total: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        cat_id: 1,
        info: "",
        page: 1,
        list_num: 7,
        status: "",
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        w_id: "",
        area: "",
        seat: "",
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
      list: [
        { name: "全部订单", state: 0 },
        { name: "已完成", state: 1 },
        { name: "未完成", state: 2 },
      ],
      //待付款
      obligationTable: [],
      falag: false,
      orderFalag: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeState(item) {
      this.getInfo.status = item;
      this.getListOrder(this.getInfo);
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.page_num = value;
      this.catalogue();
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value;
      this.getListOrder(this.getInfo);
    },
    //拒绝
    refOpen(row, form) {
      this.openState = false;
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    //同意
    conOpen(row, form) {
      this.openState = true;
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    //删除打开
    searchOpen(row, form) {
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    // 删除
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
      });
      this.judge(info);
    },
    // 查看详情
    searchOpen(info) {
      this.$router.push({
        path: "/orderInfo",
        query: {
          order_id: info.id,
        },
      });
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      let info = await getAdd(this.form);
      this.judge(info);
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.catalogue();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        w_id: "",
        area: "",
        seat: "",
      };
      console.log("close");
    },
    SpeechInit() {
      this.speech = new Speech();
      this.speech.setLanguage("zh-CN");
      this.speech.init().then(() => {});
      console.log(1);
    },
    //播放按钮
    play() {
      this.speech
        .speak({
          text: "你有新的订单，请查收!",
          listeners: {
            //开始播放
            onstart: () => {
              console.log("Start utterance");
            },
            //判断播放是否完毕
            onend: () => {
              console.log("End utterance");
            },
            //恢复播放
            onresume: () => {
              console.log("Resume utterance");
            },
          },
        })
        .then(() => {
          console.log("读取成功");
        });
    },
    //暂停
    paused() {
      this.speech.pause();
    },
    //从暂停处继续播放
    goahead() {
      this.speech.resume();
    },
    //打开订单提示音
    openOrder() {
      this.orderFalag = false;
      var data = {
        page: 1,
        list_num: 10,
        status: 1,
      };
      this.getListOrderStatus(data);
      this.timer = window.setInterval(() => {
        setTimeout(() => {
          this.getListOrderStatus(data);
        }, 0);
      }, 3000);
    },
    //关闭订单提示音
    closeOrder() {
      this.orderFalag = true;
      window.clearInterval(this.timer);
    },
    //目录接口
    async catalogue() {
      this.getListOrder(this.getInfo);
      // let info = await getList(this.getInfo);
      // console.log(info)
      // this.tableData = info.data.data.data;
      // this.exp.total = info.data.data.total;
    },
    /*------------------------------网络请求函数----------------------------*/
    //获取订单信息
    async getListOrder(data) {
      let res = await getList(data);
      console.log(res);
      if (res.data.code == 1) {
        //  console.log(res.data.data.data.length,this.tableData.length)
        // if(res.data.data.data.length>this.tableData.length){
        //     this.play();
        //    console.log("播放订单")
        // }
        this.tableData = res.data.data.data;
        this.exp.total = res.data.data.total;
      } else {
        this.$message.error(res.message);
      }
    },
    //待发货
    async getListOrderStatus(data) {
      let res = await getList(data);
      console.log(res);

      if (res.data.code == 1) {
        //  console.log(res.data.data.data.length,this.tableData.length)
        // if(res.data.data.data.length>this.tableData.length){
        //     this.play();
        //    console.log("播放订单")
        // }
        if (res.data.data.total > this.obligationTable) {
          if (this.falag) {
            this.play();
            console.log("播放订单");
          }
        }
        this.falag = true;
        this.obligationTable = res.data.data.total;
        this.getListOrder(this.getInfo);
        // console.log(this.obligationTable);
      } else {
        this.$message.error(res.message);
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.SpeechInit();

    this.catalogue();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  //离开页面取消语音
  destroyed() {
    this.speech.cancel();
    window.clearInterval(this.timer);
  }, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.border {
  display: flex;
}
.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}
.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}
.image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
  overflow: hidden;
}
</style>